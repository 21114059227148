* {
  box-sizing: border-box;
}

/* Style the list (remove margins and bullets, etc) */
ul {
  list-style-type: none;
}

/* Style the list items */
ul li {
  border: 1px solid #ddd;
  margin-top: -1px; /* Prevent double borders */
  background-color: #f6f6f6;
  padding: 12px;
  text-decoration: none;
  font-size: 18px;
  color: black;
  display: block;
  position: relative;
  padding-inline-start: 0;
  padding: 0;
  margin: 0;
}

.categories-list {
  padding-left: 0;
  margin: auto;
  max-width: 400px;
}

.new-category button {
  background-color: var(--success);
  color: white;
  margin: auto;
  width: 100%;
  border: none;
}

/* Add a light grey background color on hover */
ul li:hover {
  background-color: #eee;
}

input {
  border: none;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 15px;
  border-radius: 8px;
}

input:hover {
  background-color: lightyellow;
}

/* Style the close button (span) */
.delete {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 0;
  padding: 12px 16px;
  transform: translate(0%, -50%);
  border-radius: 8px;
}

.delete:hover {
  background: lightcoral;
}

.save-categories {
  border: none;
  border-radius: 10px;
  padding: 10px;
  background-color: var(--success);
  color: white;
}
