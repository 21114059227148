:root {
  --success: green;
  --error: red;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table th,
table td {
  white-space: nowrap;
}

.login-form {
  margin: 100px auto;
  padding: 30px;
  width: 400px;
}

.logout-button {
  padding: 10px;
  margin-left: 25px;
  margin-bottom: 25px;
}

.upload-button {
  position: relative;
  height: 180px;
}

.upload-img-description {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(29, 106, 54, 0.85);
  color: #fff;
  visibility: hidden;
  opacity: 0;
  margin: 0;
  padding: 20%;
  transition: opacity 0.2s, visibility 0.2s;
}

.upload-button:hover .upload-img-description {
  visibility: visible;
  opacity: 1;
}
